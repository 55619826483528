@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Russo+One&display=swap);
/*
  font-family: 'Montserrat', sans-serif;
  font-family: 'Russo One', sans-serif;
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: auto !important;
}

body {
  font-family: "Russo One", sans-serif;
  color: #fff;
  background: url(/static/media/atsundown_background_1.94ef999e.webp) repeat;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Russo One", sans-serif;
}

p {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
}

img {
  max-width: 100%;
}

/*** 

=======================
    Margin and Padding
=======================

***/
/* 
    Margin Top styles
*/
.mar-0 {
  margin: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-150 {
  margin-top: 150px;
}

/* 
  Margin Bottom Styles
*/
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

/* 
  Margin Right Styles
*/
.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.centered {
  text-align: center;
}

.container {
  max-width: 1630px !important;
}

.layout {
  position: relative;
  z-index: 1;
}

.heightcenter {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.screen-img {
  position: relative;
  z-index: 1;
}

.screen-img h1,
h2 {
  font-size:2.375rem;
  font-weight: normal;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.screen-img video {
  width: 100%;
  border: 4px solid #fff;
  margin-bottom: 20px;
}

.screen-img img {
  width: 100%;
  border: 4px solid #fff;
  margin-bottom: 20px;
}

.screen-img iframe {
  width: 100%;
  border: 4px solid #fff;
  margin-bottom: 20px;
}

.screen-bottom-img {
  text-align: center;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  margin-bottom: 10px;
}
.sb-block {
  margin: 0px 10px;
}

.screen-button-steam {
  transition: all 0.25s ease-out;
}

.screen-button-steam:hover {
  transform: scale(0.9);
}

.screen-button-steam img {
  border: 2px solid #de00d1;
  border-radius: 5px;
  padding: 10px 30px;
  height: auto;
  width: auto;
  margin-bottom: 15px;
  background: black;
}

.screen-button-ps4 {
  transition: all 0.25s ease-out;
}

.screen-button-ps4:hover {
  transform: scale(0.9);
}

.screen-button-ps4 img {
  border: 2px solid #002990;
  border-radius: 5px;
  padding: 10px 30px;
  height: auto;
  width: auto;
  margin-bottom: 15px;
  background: black;
}

.screen-button-xbox {
  transition: all 0.25s ease-out;
}

.screen-button-xbox:hover {
  transform: scale(0.9);
}

.screen-button-xbox img {
  border: 2px solid #0e6e00;
  border-radius: 5px;
  padding: 10px 30px;
  height: auto;
  width: auto;
  margin-bottom: 15px;
  background: black;
}

.screen-button-switch {
  transition: all 0.25s ease-out;
}

.screen-button-switch:hover {
  transform: scale(0.9);
}

.screen-button-switch img {
  border: 2px solid #980000;
  border-radius: 5px;
  padding: 10px 30px;
  height: auto;
  width: auto;
  margin-bottom: 15px;
  background: black;
}

.bottom-paragraph {
  font-size: 12px;
  color: #ffffff59;
}

.bottom-paragraph-bold {
  font-size: 14px;
  color: #ffffffd1;
}

/* character-img */
.character-img img {
  height: 90vh;
  width: auto;
}

.character-img.spacialchar img {
  height: 570px;
  width: auto;
  transform: scale(.8);
}

.single-buy-now {
  margin-bottom: 25px;
  transition: all 0.25s ease-out;
}

.single-buy-now:hover {
  transform: scale(0.9);
}

.single-buy-now img {
  border: 2px solid;
  border-radius: 5px;
  padding: 10px 30px;
  height: 100px;
  width: auto;
  background: black;
}

.single-buy-now.one img {
  border-color: #de00d1;
}

.single-buy-now.two img {
  border-color: #002990;
}

.single-buy-now.three img {
  border-color: #0e6e00;
}

.single-buy-now.four img {
  border-color: #980000;
}

.mainmenu {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 35px 0;
  z-index: 99;
}

.spotlight {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}
.top-divider {
  height: 5vh;
  width: 100%;
}
.slick-dots li button:before {
  color: #ddd !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

/* header-menu */
.header-menu {
  margin-top: 15px;
  float: right;
}

.header-menu a {
  color: #fff !important;
  text-decoration: none;
  padding: 0 1.8rem;
  height: 100%;
  cursor: pointer;
  font-size: 18px;
  transition: 0.4s;
  font-family: "Russo One", sans-serif;
}

.header-menu a:hover {
  text-decoration: none;
}
.player-wrapper{
  max-width: 640px !important;
 }


@media (max-width: 1199px) {
  .character-img img {
    display: none;
  }
}
@media (min-width: 767px) and (max-width: 1199px) {
 .screen-img .player-wrapper{
  max-width: 640px !important;
 }
  .main-menu {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    background: #000;
  }
}

@media (max-width: 767px) {
  .header-menu {
    display: none;
  }
}

.sitelogo {
  float: left;
  cursor: pointer;
}

.socialLinks {
  float: right;
  /* margin-left: 30px; */
}

.socialLinks a {
  margin-left: 15px;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  height: 50px;
  line-height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  transition: 0.4s;
  text-align: center;
}

.socialLinks a:hover {
  color: #fff;
  background: #e86624;
}

.socialLinks a img {
  width: 40px;
  height: 40px;
}

@media (max-width: 768px) {
  .spotlight {
    display: none;
  }
  .screen-img > div {
    width: 100% !important;
    text-align: center;
  }
  .screen-img video {
    width: 100% !important;
    margin: 10px auto;
  }
  .character-img img {
    display: none;
  }
  .sitelogo {
    display: block;
    text-align: center;
    margin-top: 8px;
  }
  .sitelogo img {
    width: 145px;
  }
  .socialLinks {
    margin-left: 0;
    text-align: center;
  }
  .socialLinks a {
    margin-left: 0;
    font-size: 20px;
  }
  .mainmenu {
    padding: 15px 0;
    background: #000000d1;
  }
  .screen-img h1,
  h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .screen-img p {
    font-size: 12px;
  }
  .screen-bottom-img {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }
  .fp-tableCell {
    height: 100vh !important;
  }
  .fp-section {
    height: 100vh !important;
    padding: 0 !important;
  }
  .single-buy-now img {
    height: 60px;
  }
  .section {
    padding: 40px 0;
  }
  .heightcenter > img {
    width: 30%;
  }
  .h2,
  h2 {
    font-size: 1.5rem !important;
  }
  .heightcenter {
    height: auto;
  }
  .mt-mob {
    margin-top: 40px;
  }
  .single-buy-now {
    margin-bottom: 0;
  }
}

